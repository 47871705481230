import fetch from 'auth/FetchInterceptor'
import FormService from "./FormService";

const AuthService = {}

AuthService.login = function (data) {
	return fetch({
		url: '/login-admin',
		method: 'post',
		data: FormService.get(data)
	})
}

AuthService.logout = function () {
	return fetch({
		url: '/logout',
		method: 'post'
	})
}

AuthService.forget = function (data) {
	return fetch({
		url: '/forget',
		method: 'post',
		data: FormService.get(data)
	})
}

AuthService.reset = function (data) {
	return fetch({
		url: '/reset',
		method: 'post',
		data: FormService.get(data)
	})
}

AuthService.assign = function (data) {
	return fetch({
		url: '/assign-password',
		method: 'post',
		data: FormService.get(data)
	})
}

AuthService.me = function () {
	return fetch({
		url: '/auth/user',
		method: 'get',
	})
}

AuthService.userInfo = function () {
	return fetch({
		url: '/auth/user/info',
		method: 'get',
	})
}

AuthService.userInfoUpdate = function (data) {
	return fetch({
		url: '/auth/user/info/edit',
		method: 'post',
		data: FormService.get(data)
	})
}

AuthService.userAddress =  function () {
	return fetch({
		url: '/auth/user/addresses',
		method: 'get',
	})
}

AuthService.userAddress.add = function (data) {
	return fetch({
		url: '/auth/user/addresses/add',
		method: 'post',
		data: FormService.get(data)
	})
}


AuthService.userAddress.edit = function (uid, data) {
	return fetch({
		url: `/auth/user/addresses/${uid}/edit`,
		method: 'post',
		data: FormService.get(data)
	})
}

AuthService.userAddress.delete = function (uid) {
	return fetch({
		url: `/auth/user/addresses/${uid}/delete`,
		method: 'delete',
	})
}

AuthService.userChannel =  function () {
	return fetch({
		url: '/auth/user/channels',
		method: 'get',
	})
}

AuthService.userChannel.add = function (data) {
	return fetch({
		url: '/auth/user/channels/add',
		method: 'post',
		data: FormService.get(data)
	})
}


AuthService.userChannel.edit = function (uid, data) {
	return fetch({
		url: `/auth/user/channels/${uid}/edit`,
		method: 'post',
		data: FormService.get(data)
	})
}

AuthService.userChannel.delete = function (uid) {
	return fetch({
		url: `/auth/user/channels/${uid}/delete`,
		method: 'delete',
	})
}


AuthService.userAccesses =  function () {
	return fetch({
		url: '/auth/user/accesses',
		method: 'get',
	})
}

AuthService.userAccesses.get = function () {
	return fetch({
		url: '/auth/user/accesses/get',
		method: 'get',
	})
}

AuthService.userAccesses.delete = function (uid) {
	return fetch({
		url: `/auth/user/accesses/${uid}/delete`,
		method: 'delete',
	})
}

export default AuthService;