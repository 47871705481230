import React from 'react'
import {routes} from "./routes";

export const publicRoutes = [
    {
        key: 'login',
        path: routes.login,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'forgot-password',
        path: routes.forget,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    },
    {
        key: 'reset-password',
        path: routes.reset,
        component: React.lazy(() => import('views/auth-views/authentication/reset-password')),
    },
    {
        key: 'assign-password',
        path: routes.assign,
        component: React.lazy(() => import('views/auth-views/authentication/assign-password')),
    }
]

export const protectedRoutes = [
    {
        key: 'dashboard',
        path: routes.dashboard,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'users',
        path: routes.users,
        component: React.lazy(() => import('views/app-views/user-list')),
    },
    {
        key: 'settings',
        path: routes.settings,
        component: React.lazy(() => import('views/app-views/setting')),
    },
    {
        key: 'support',
        path: routes.support,
        component: React.lazy(() => import('views/app-views/support')),
    },
]