export const routes = {
    login: '/login',
    forget: '/forgot',
    reset: '/reset',
    assign: '/assign-password',
    dashboard: '/',
    users: '/users',
    settings: '/settings/*',
    support: '/support/*',
    supportNav: '/support/'
}